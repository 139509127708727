<template>
  <el-table
    :data="info"
    stripe
    class="radius"
    height="530"
    v-loading="loading"
    style="width: 100%">
    <el-table-column
      prop="block_num"
      label="block_num">
      <template slot-scope="scope">
        <router-link class="field-block-num" :to="`/block/${scope.row.block_num}`">
          {{ scope.row.block_num }}
        </router-link>
      </template>
    </el-table-column>
    <el-table-column
      prop="timestamp"
      label="block_time">
    </el-table-column>
    <el-table-column
      prop="Txns"
      label="Txns">
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    info: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  }
}
</script>

<style scoped lang="scss">
.field-block-num {
  color: dodgerblue;
  font-weight: bold;
}
</style>
