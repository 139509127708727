<template>
  <div class="home-page">
    <div class="columns">
      <div
        class="column"
        v-for="item in info"
        :key="item.key"
      >
        <div class="info-box">
          <img :src="item.icon" :alt="item.name"/>
          <div class="info">
            <span>{{ item.name }}</span>
            <p>{{ infoData[item.key] }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column" style="width: 100%">
        <h3 class="info-list-title">区块交易</h3>
        <InfoListBox
          :info="blockInfo.data"
          :loading="blockInfo.loading" />
      </div>
      <!-- <div class="column">
        <h3 class="info-list-title">TOP 账号</h3>
        <InfoListTop
          :info="topList.data"
          :loading="topList.loading" />
      </div> -->
    </div>
  </div>
</template>

<script>
import InfoListBox from './components/InfoListBox.vue'
// import InfoListTop from './components/InfoListTop.vue'
import moment from 'moment'
export default {
  name: 'Home',
  components: {
    InfoListBox
    // InfoListTop
  },
  data () {
    return {
      info: [
        { name: '最新区块', key: 'block', icon: require('@/assets/images/block.png') },
        { name: '不可逆区块', key: 'irreversible', icon: require('@/assets/images/irreversible.png') }
      ],
      infoData: {
        irreversible: '获取中...',
        block: '获取中...'
      },
      socket: null,
      blockInfo: {
        data: [],
        loading: false
      },
      topList: {
        data: [],
        loading: false
      }
    }
  },
  created () {
    this.blockInfo.loading = true
    // this.gitList()

    this.socket = this.$apis.HomeSocket.start()

    this.socket.on('get_head_block', (msg) => {
      this.infoData.irreversible = msg.last_irreversible_block_num
      this.infoData.block = msg.head_block_num
    })

    this.socket.on('get_blocks', (msg) => {
      this.blockInfo.loading = false
      this.blockInfo.data = msg.map((block) => {
        const { block_num: blockNum, timestamp, transactions } = block
        return {
          block_num: blockNum,
          timestamp: this.correct(timestamp),
          Txns: transactions.length
        }
      })
    })
  },
  methods: {
    async gitList () {
      this.topList.loading = true
      const res = await this.$apis.common.getTop()
      this.topList.loading = false
      if (res) {
        this.topList.data = res.data
      }
    },
    correct (time) {
      const timeZ = time[time.length - 1]
      if (timeZ !== 'Z') {
        time = time + 'Z'
      }
      return moment(time).format('yyyy-MM-DD HH:mm:ss')
    }
  },
  beforeDestroy () {
    this.socket.close()
  }
}
</script>

<style lang="scss" scope>
@import './index.scss'
</style>
