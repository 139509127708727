<template>
  <div class="info-paper">
    <span>{{title}}</span>
    <p>{{info}}</p>
  </div>
</template>

<script>

export default {
  props: [
    'title',
    'info'
  ]
}
</script>

<style lang="scss" scope>
.info-paper {
  display: flex;
  flex-direction: column;
  font-family: Menlo, Monaco, 'Courier New', monospace;

  span {
    font-size: 16px;
    color: #888;
  }
  p {
    font-size: 18px;
    word-break: break-all;
    color: #000;
    font-weight: 400;
  }
}
</style>
