<template>
  <div class="search-block container">
    <div style="padding: 1rem">
      <div class="search-block-info">
        <div class="search-block-info_head">
          <span>Block</span>
          <p>{{blockInfo.block_num}}</p>
        </div>
        <InfoPaper
          style="padding-top: 10px;"
          v-for="(item, key) in field"
          :key="key"
          :title="item"
          :info="blockInfo[key]"
        />
      </div>
    </div>
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Transactions" name="first">
        <div v-if="blockInfoArr.length === 0" >
          <p style="background: aliceblue">未找到交易</p>
        </div>
        <div class="column" v-else>
          <InfoListBlock
            :info='blockInfoArr'
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="Raw data" name="second">
        <json-viewer
          :value="blockInfo"
          :expand-depth=5
          copyable
          sort>
        </json-viewer>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import InfoPaper from '@/components/InfoPaper'
import InfoListBlock from '@/components/InfoListBlock'
import moment from 'moment'
import JsonViewer from 'vue-json-viewer'

export default {
  props: ['num'],
  components: {
    InfoPaper,
    JsonViewer,
    InfoListBlock
  },
  data () {
    return {
      activeTab: 'first',
      listLoading: true,
      res: {
        block_num_or_id: this.num
      },
      blockInfo: {},
      blockInfoArr: [],
      field: {
        id: 'Block Hash',
        timestamp: 'Timestamp',
        producer_signature: 'Producer Signature',
        confirmed: 'Confirmed',
        previous: 'Previous Hash',
        transaction_mroot: 'M-Root Transaction',
        action_mroot: 'M-Root Action'
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    // 时间处理
    correct (time) {
      if (!time) return ''
      const timeZ = time[time.length - 1]
      if (timeZ !== 'Z') {
        time = time + 'Z'
      }
      return moment(time).format('yyyy-MM-DD HH:mm:ss')
    },
    async getList () {
      const req = await this.$apis.blockchain.getBlock(this.res)
      if (req) {
        this.blockInfo = {
          ...req,
          timestamp: this.correct(req.timestamp)
        }
      }
      this.dataList()
    },
    dataList () {
      const arr = []
      const s = this.blockInfo.transactions
      for (let i = 0; i < s.length; i++) {
        const item = s[i]
        const obj = {}
        obj.id = item.trx.id
        const size = item.trx.transaction.actions
        obj.len = size.length
        arr.push(obj)
      }
      this.blockInfoArr = arr
    }
  }
}
</script>

<style lang="scss" scope>
.search-block {
  margin-top: 20px;
  padding: 0 15px;
  font-family: Consolas,Menlo,Courier,monospace;
  &-info {
    background: #fff;
    padding: 20px;
    border-radius: 15px;

    &_head {
      padding-bottom: 10px;
      border-bottom: 3px solid #eeeeee;
      margin-bottom: 15px;
    }
  }
}
</style>
