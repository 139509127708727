<template>
  <div class="search-block container">
    <div style="padding: 1rem">
      <div class="search-block-info">
        <div class="search-block-info_head">
          <span>Transaction Id</span>
          <p>{{trxid}}</p>
        </div>
        <el-tag
          effect="dark">
          {{ status }}
        </el-tag>
        <InfoPaper title="Block Time" :info="blockTime" style="padding-top: 10px;" />
        <InfoPaper title="Expiration Time" :info="expirationTime" style="padding-top: 10px;" />
        <div class="columns">
          <div class="column">
            <InfoPaper title="Ref" :info="ref" />
          </div>
          <div class="column">
            <InfoPaper title="Block" :info="blockNum" />
          </div>
        </div>
      </div>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="Actions" name="first">
        <div v-if={trxDataInfo} class="trading-info">
          <div class="title">交易信息</div>
          <div v-for="(item, key) in trxDataInfo" :key="key" class="row">
            <span>{{ key }}：</span>
            <span class="uris-btn" @click="lookData(item && item[0])" v-if="key === 'uris'">{{ item[0] }}</span>
            <span v-else>{{ item }}</span>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Raw data" name="second">
        <json-viewer
          :value="trxHistory"
          :expand-depth=5
          copyable
          sort />
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      title="ipfs原数据"
      :visible.sync="dialogVisible"
      width="30%">
      <json-viewer
        :value="ipfsData"
        :expand-depth=5
        copyable
        sort />
    </el-dialog>
  </div>
</template>

<script>
import InfoPaper from '@/components/InfoPaper'
import moment from 'moment'
import JsonViewer from 'vue-json-viewer'

export default {
  props: ['trxId'],
  components: {
    InfoPaper,
    JsonViewer
  },
  data () {
    return {
      reqtrxidt: {
        trxid: this.trxId,
        limit: 10,
        page: 1
      },
      trxid: '',
      status: '',
      blockTime: '',
      blockNum: '',
      account: '',
      activeName: 'first',
      // 接口8数据
      ref: '',
      expirationTime: '',
      trxActions: [],
      trxHistory: {},
      tradingInfo: {},
      trxDataInfo: {},
      dialogVisible: false,
      ipfsData: ''
    }
  },
  created () {
    this.getcode()
  },
  methods: {
    // 时间处理
    correct (time) {
      if (!time) return ''
      const timeZ = time[time.length - 1]
      if (timeZ !== 'Z') {
        time = time + 'Z'
      }
      return moment(time).format('yyyy-MM-DD HH:mm:ss')
    },

    // 查询交易接口,接口5
    async gettrxid () {
      const res = await this.$apis.common.getTrxid(this.reqtrxidt)
      if (res && res.code === 1) {
        this.trxid = res.data.trx_id
        this.status = res.data.status
        this.blockTime = this.correct(res.data.block_time)
        this.blockNum = res.data.block_num
        this.forms = res.data.from
        this.to = res.data.to
        this.account = res.data.account
        // this.quantity = res.data.quantity
        this.memo = res.data.memo
        this.symbol = res.data.symbol
        await this.getcode(res.data.block_num)
      }
    },
    async getcode () {
      // 接口8
      const req = {
        id: this.trxId
        // block_num_hint: e
      }
      const res = await this.$apis.blockchain.getTransaction(req)
      // if (res) {
      //   this.trxHistory = res.data
      //   this.ref = res.data.trx.trx.ref_block_num
      //   this.expirationTime = this.correct(res.data.trx.trx.expiration)
      //   this.trxActions = res.data.trx.trx.actions
      // }
      if (res) {
        this.trxHistory = res
        this.trxid = res.id
        this.status = res.trx.receipt.status
        this.blockTime = this.correct(res.block_time)
        this.blockNum = res.block_num
        this.ref = res.trx.trx.ref_block_num
        this.expirationTime = this.correct(res.trx.trx.expiration)
        // this.forms = res.from
        // this.to = res.to
        this.account = res.trx.trx.actions[0].data.account
        this.trxActions = res.trx.trx.actions
        const trxDataInfo = res.traces && res.traces[0] && res.traces[0].act && res.traces[0].act.data
        // this.quantity = res.quantity
        // this.memo = res.memo
        // this.symbol = res.symbol
        // const uris = trxDataInfo.uris
        // if (uris && uris.length > 0) {
        //   const newUris = {}
        //   for (let i = 0; i < uris.length; i++) {
        //     const item = uris[i]
        //     const result = await this.getTradingInfo(item)
        //     if (result) {
        //       newUris[item] = result
        //     } else {
        //       newUris[item] = ''
        //     }
        //   }
        //   console.log(newUris)
        //   trxDataInfo.uris = newUris
        // }
        this.trxDataInfo = trxDataInfo
      }
    },
    async lookData (usi) {
      if (!usi) return
      this.ipfsData = await this.getTradingInfo(usi)
      this.dialogVisible = true
    },
    async getTradingInfo (usi) {
      if (!usi) return
      const res = await this.$apis.common.downloadInfo({ cid: usi })
      if (!res || res.code !== 'ok') return
      console.log(res)
      return res.data
      // this.tradingInfo = typeof res.data === 'string' ? JSON.parse(res.data) : res.data
    },
    goAccount (id) {
      this.$router.push(`/account/${id}`)
    },
    goIpfsHash (hash) {
      this.$router.push(`/hash/${hash}`)
    }
  }
}
</script>

<style lang="scss" scope>
@import './index.scss'
</style>
