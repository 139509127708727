<template>
  <div class="search-block">
    <div class="search-block-info">
      <div v-if="accountInfo.account_name" class="search-block-info_head">
        <span>{{accountInfo.account_name}}</span>
        <p>created at {{ correct(accountInfo.created) }}</p>
      </div>
      <el-tabs v-model="activeTab">
        <el-tab-pane label="代币" name="first">
          <div class="code-outerbox">
            <div class="code-box-wrap" v-for="item in codeList" :key="item.code">
              <div class="code-box">
                <p>{{item.balance}}</p>
                <span>{{item.code}}</span>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="权限" name="second">
          <InfoPaper
            style="padding-top: 10px;"
            v-for="(item, key) in accountInfo.permissions"
            :key="key"
            :title="item.perm_name"
            :info="item.required_auth.keys[0].key"
          />
        </el-tab-pane>
      </el-tabs>
    </div>

    <div class="trxtable-wrapper">
      <el-table :data="history" stripe>
        <el-table-column
            label="Trx Hash"
            align="left">
          <template slot-scope="{row}">
            <router-link class="trx_id custom-router-link" :to="`/trxid/${row.trx_id}`">
              {{ row.trx_id }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
            label="Time"
            align="center">
          <template slot-scope="{row}">
            <p> {{ correct(row.block_time) }}</p>
          </template>
        </el-table-column>
        <!-- <el-table-column
            label="Info"
            align="left"
        >
          <template slot-scope="{row}" style="flex-wrap: wrap;">
            <div class="transfer-info">
              <div>
                <router-link class="name-id custom-router-link" :to="`/account/${row.from}`">
                  {{ row.from }}
                </router-link>
                <i class="el-icon-right" style="margin: 8px"></i>
                <router-link class="name-id custom-router-link" :to="`/account/${row.to}`">
                  {{ row.to }}
                </router-link>
              </div>
              <span>{{ row.quantity }} {{ row.symbol }}</span>
              <span>{{ row.account }}</span>
            </div>
            <span class="memo" >MEMO : {{ row.memo }}</span>
          </template>
        </el-table-column> -->
        <el-table-column
            label="Ipfs Hash"
            align="center">
          <template slot-scope="{row}">
            <router-link class="trx_id custom-router-link" :to="`/hash/${row.this_hash}`">
              {{ row.this_hash }}
            </router-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination :total="total" :autoScroll="true" :page.sync="reqAccount.page" :limit.sync="reqAccount.limit" @pagination="getAcclist" />
    </div>
  </div>
</template>

<script>
import InfoPaper from '@/components/InfoPaper'
import Pagination from '@/components/Pagination'
import moment from 'moment'
export default {
  props: ['name'],
  components: {
    InfoPaper,
    Pagination
  },
  data () {
    return {
      activeTab: 'first',
      codeList: [],
      history: [],
      accountInfo: {
        account_name: this.name,
        created: '',
        permissions: []
      },
      total: 0,
      reqAccount: {
        account_name: this.name,
        limit: 6,
        page: 1
      }
    }
  },
  created () {
    this.getAList()
    this.getCodes()
    this.getAcclist()
  },
  methods: {
    async getAList () {
      const data = {
        account_name: this.name
      }
      const res = await this.$apis.blockchain.getAcc(data)
      console.log('getAList', res)
      if (res) {
        this.accountInfo = res
      }
    },
    async getCodes () {
      const res = await this.$apis.common.getCode()

      console.log('获取 code 数据：', res)
      if (res) {
        const codeList = []
        for (const item of res.data.filter(item => item.code.length > 0)) {
          try {
            // 获取代币数据，接口7
            const r = await this.$apis.blockchain.getCurrency({
              code: item.code,
              account: this.name
            })
            if (r.length > 0) {
              codeList.push({
                code: item.code,
                balance: r.join('、')
              })
            }
          } catch (e) {
            console.log(e)
          }
        }
        this.codeList = codeList
      }
    },
    async getAcclist () {
      // 账号转账交易记录，接口4
      const res = await this.$apis.common.getAccount(this.reqAccount)
      console.log('*-*-*-*-*-*', res)
      if (res) {
        this.total = Number(res.data.count)
        this.history = res.data.list
      } else {
        console.log('获取失败')
      }
    },
    correct (time) {
      console.log(typeof time, Number(time))
      time = isNaN(Number(time)) ? time : new Date(Number(time))
      if (!time) return ''
      const timeZ = time[time.length - 1]
      if (timeZ !== 'Z') {
        time = time + 'Z'
      }
      return moment(time).format('yyyy-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss'
</style>
