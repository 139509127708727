<template>
  <div class="box-wrapper">
    <el-table
      :data="info"
      stripe
      class="radius"
      v-loading="loading"
      style="width: 100%">
      <el-table-column
        prop="id"
        label="ID">
        <template slot-scope="scope">
          <router-link class="field-block-num" :to="`/trxid/${scope.row.id}`">
            {{ scope.row.id }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="len"
        label="操作数量">
      </el-table-column>
    </el-table>

    <div v-if="tradingInfo.id" class="trading-info">
      <div class="title">交易信息</div>
      <div class="row">
        <span>id：</span>
        <span>{{ tradingInfo.id }}</span>
      </div>
      <div v-if="tradingInfo.name" class="row">
        <span>申请人：</span>
        <span>{{ tradingInfo.name }}</span>
      </div>
      <div v-if="tradingInfo.funding" class="row">
        <span>申请经费：</span>
        <span>{{ tradingInfo.funding }} 元</span>
      </div>
      <div v-if="tradingInfo.funding_use" class="row">
        <span>经费用途：</span>
        <span>{{ tradingInfo.funding_use }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    info: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tradingInfo: {}
    }
  },
  created () {
    this.getTradingInfo()
  },
  methods: {
    async getTradingInfo () {
      if (!this.info[0]) return
      const res = await this.$apis.common.getFormData({ trx_id: this.info[0].id })
      if (!res || res.code !== 'ok') return
      this.tradingInfo = typeof res.data === 'string' ? JSON.parse(res.data) : res.data
      // this.tradingInfo = {
      //   id: '1231356456445',
      //   name: '木木',
      //   funding: 120,
      //   funding_use: '测试'
      // }
    }
  }
}
</script>

<style lang="scss" scope>
$theme-color: dodgerblue;

.box-wrapper {
  position: relative;
  // box-shadow: 0 0 5px 4px rgba($color: #888, $alpha: .1);
  border-radius: 15px;
  overflow: hidden;
  padding-top: 0;
  // background: #fff;
  min-height: 100px;
  //max-width: 500px;

  .table {
    background: transparent;

    tbody {
      tr {
        background: #fff!important;
      }
    }
  }
  .table td {
    border: 0;
  }
  .field_block_num {
    color: dodgerblue;
    font-weight: bold;
    max-width: 14em;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
  }
  .trading-info {
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    background: #fff;

    .title {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 10px;
    }

    .row {
      font-size: 18px;
      line-height: 30px;
    }

    .link {
      color: rgb(89, 86, 255);
      cursor: pointer;
    }
  }
}

</style>
