<template>
  <div class="search-block container">
    <div style="padding: 1rem">
      <div class="search-block-info">
        <div class="search-block-info_head">
          <span>Transaction Id</span>
          <router-link class="custom-router-link" :to="`/trxid/${blockData.trx_id}`">
            <p>{{blockData.trx_id}}</p>
          </router-link>
        </div>
        <!-- <el-tag
          effect="dark">
          {{ status }}
        </el-tag> -->
        <router-link class="custom-router-link" :to="`/account/${blockData.account}`">
          <InfoPaper title="account" :info="blockData.account" style="margin-top: 10px;" />
        </router-link>
        <InfoPaper title="Ipfs Hash" :info="blockData.this_hash" style="margin-top: 10px;" />
        <div class="columns">
          <div class="column">
            <InfoPaper title="Block Time" :info="correct(blockData.block_time)" />
          </div>
          <div class="column">
            <router-link class="custom-router-link" :to="`/block/${blockData.block_num}`">
              <InfoPaper title="Block" :info="blockData.block_num" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-if="formData.id" class="form-info">
      <div class="title">交易信息</div>
      <div class="row">
        <span>id：</span>
        <span>{{ formData.id }}</span>
      </div>
      <div v-if="formData.name" class="row">
        <span>申请人：</span>
        <span>{{ formData.name }}</span>
      </div>
      <div v-if="formData.funding" class="row">
        <span>申请经费：</span>
        <span>{{ formData.funding }} 元</span>
      </div>
      <div v-if="formData.funding_use" class="row">
        <span>经费用途：</span>
        <span>{{ formData.funding_use }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import InfoPaper from '@/components/InfoPaper'
import moment from 'moment'

export default {
  props: ['hash'],
  components: {
    InfoPaper
  },
  data () {
    return {
      formData: {},
      blockData: {}
    }
  },
  created () {
    this.getIpfsHash()
  },
  methods: {
    // 查询交易接口,接口5
    async getIpfsHash () {
      const res = await this.$apis.common.getIpfsHash({ hash: this.hash })
      if (res && res.code === 'ok') {
        let fromData = res.data.from_data
        fromData = typeof fromData === 'string' ? JSON.parse(fromData) : fromData
        this.formData = fromData
        this.blockData = res.data.res
      }
    },
    goAccount (id) {
      this.$router.push(`/account/${id}`)
    },
    correct (time) {
      console.log(typeof time, Number(time))
      time = isNaN(Number(time)) ? time : new Date(Number(time))
      if (!time) return ''
      const timeZ = time[time.length - 1]
      if (timeZ !== 'Z') {
        time = time + 'Z'
      }
      return moment(time).format('yyyy-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style lang="scss" scope>
@import './index.scss'
</style>
